<template>
    <div class="box">
        <img :src="product.img" :class="{series: (type === 'series')}" alt="">
        <div class="left">
            <h3>
                <span v-if="(product['@type'] === 'series')" class="tag">serie</span>
                <span v-if="(product['@type'] === 'dlc')" class="tag">DLC</span>
                <span v-if="(product['@type'] === 'game')" class="tag">hra</span>
                {{ product.name }}
            </h3>
            <div v-if="(product['@type'] === 'series')" class="seriesMessage">Hra je součástí serie. <br> Pokud ji sleduješ, budeme tě upozorňovat na nové díly</div>
        </div>
        <div class="props" v-if="product.watchedProps">

            <div class="you-are-watching">{{ youAreWatching }}</div>
            <div v-for="watchedProp, index in product.watchedProps"
                 :key="index"
                 class="btn small"
                 :class="{ selected: watchedProp }"
                 @click="changeProp([product, index])"
            >
                <i :class="className(index)"></i>
            </div>
        </div>
        <div class="props" v-if="!product.watchedProps">
            <div class="btn" @click="watchProduct(product)"> {{ watchThis }} </div>
        </div>
    </div>

</template>
<script>
    import {mapMutations} from "vuex";

    export default {
        props: ['product', 'type'],
        methods: {
            ...mapMutations(['changeProp', 'watchProduct']),
            className(index) {
                let classes = {
                    inStock: "fas fa-boxes",
                    priceDump: "fas fa-skiing",
                    newDlc: "fas fa-download",
                    newGame: "fas fa-star",
                }
                return classes[index];
            }
        },
        computed: {
            youAreWatching() {
                let array = {
                    'games': "tuto hru již sleduješ",
                    'dlcs': "toto dlc již sleduješ",
                    'series': "tuto serii již sleduješ",
                }
                return array[this.type]
            },
            watchThis() {
                let array = {
                    'games': "Sledovat hru",
                    'dlcs': "Sledovat dlc",
                    'series': "Sledovat serii",
                }
                return array[this.type]
            }
        }
    }
</script>

<style scoped lang="scss">
    .box {
        .btn {
            background: #222222;
        }

        img {
            max-width: 60%;
            width: 90px;
            &.series {
                width:200px;
            }
        }

        .left {
            padding: 10px 20px;
            flex-grow: 2;
            h3 {
                flex-grow: 3;
                margin: 0;
                vertical-align: center;
            }
            .seriesMessage {
                /*max-width: 250px;*/
                margin:auto;
            }

        }
        .props {
            padding: 0 20px;
            .you-are-watching {
                display: inline-block;
                padding: 5px 10px;
            }
        }

        .btn {
            text-align: center;
            &.small {
                padding: 0;
            }
            &.selected {
                color: greenyellow;
            }
        }

        @media (min-width: 900px) {
            display: flex;
            align-items: center;
            text-align: left;
            h2 {
                text-align: left;
            }
            /*justify-content: stretch;*/
        }

    }


</style>