<template>
    <div class="propChecks">
        <div v-for="watchedProp, index in product.watchedProps"
             :key="index"
             class="btn"
             :class="{ selected: watchedProp }"
             @click="changeProp([product, index]), edit(product)"
        >
            <i :class="className(index)"></i>
        </div>
    </div>
    <div class="counter">
        {{ (counter > 0)? counter+" aktivní upozornění" : "žádné upozornění"}}
    </div>
</template>

<script>
    import {mapMutations} from "vuex";

    export default {
        props: ['product'],
        methods: {
            ...mapMutations(['changeProp', 'edit']),
            className(index) {
                let classes = {
                    inStock: "fas fa-boxes",
                    priceDump: "fas fa-skiing",
                    newDlc: "fas fa-download",
                    newGame: "fas fa-star",
                }
                return classes[index];
            }
        },
        computed: {
            counter() {
                let counter = 0;
                for( const key in this.product.watchedProps) {
                     if(this.product.watchedProps[key]) counter++;
                }
                return counter;
            }
        }

    }
</script>

<style scoped lang="scss">
    .btn {
        padding: 0;
        &.selected {
            color: greenyellow;
        }
    }

    .counter {
        padding: 10px 5px 15px;
    }
</style>