import {createStore} from 'vuex'
import axios from "axios";

export default createStore({
    state: {
        products: null
    },
    mutations: {
        edit(state, clicked) {
            state.products.forEach((product)=>{
                product.edited = false
            })
            clicked.edited = true
        },
        closeEdit (state) {
            state.products.forEach((product)=>{
                product.edited = false
            })
        },
        toggleEdit(state, clicked) {
            console.log("toggleEdit")
            let newClickedState = !clicked.edited
            state.products.forEach((product)=>{
                product.edited = false
            })
            clicked.edited = newClickedState
        },
        changeProp(state, [product, i]) {
            product.watchedProps[i] = !product.watchedProps[i];
            console.log("TODO: PUT - update watch ", product['@id'])
        },
        loadData(state) {
            axios.get("/dummyData.json").then((data)=>{
                // console.log(data.data)
                state.products = data.data;
            })
        },
        watchProduct(state, product) {
            console.log("TODO: POST - new watch ", product['@id'])
        },
        removeProduct(state,product) {
            console.log("TODO: DELETE - stop watching", product['@id'])
        },
        mapProducts(state, product) {
            if(product.games != undefined && product.games.length > 0) {
                console.log('mapping games')
                product.games.forEach((game, n)=>{
                    let uuid = game['@id'];
                    state.products.forEach((watchedProduct) => {
                        if (watchedProduct['@id'] === uuid) {
                            // console.log(watchedProduct)
                            product.games[n] = watchedProduct;
                        }
                    })
                })
            }
            if(product.series != undefined && product.series.length > 0) {
                console.log('mapping series')
                product.series.forEach((serie, n)=>{
                    let uuid = serie['@id'];
                    state.products.forEach((watchedProduct) => {
                        if (watchedProduct['@id'] === uuid) {
                            // console.log(watchedProduct)
                            product.series[n] = watchedProduct;
                        }
                    })
                })
            }
            if(product.dlcs != undefined && product.dlcs.length > 0) {
                console.log('mapping dlcs')
                product.dlcs.forEach((dlcs, n)=>{
                    let uuid = dlcs['@id'];
                    state.products.forEach((watchedProduct) => {
                        if (watchedProduct['@id'] === uuid) {
                            // console.log(watchedProduct)
                            product.dlcs[n] = watchedProduct;
                        }
                    })
                })
            }
        }
    },
    actions: {},
    modules: {}
})
