<template>
    <div class="box">
        <img :src="product.img" alt="">
            <h2>
                <span v-if="(product['@type'] === 'series')" class="tag">serie</span>
                <span v-if="(product['@type'] === 'dlc')" class="tag">DLC</span>
                <span v-if="(product['@type'] === 'game')" class="tag">hra</span>
                {{ product.name }}
            </h2>
        <div class="propLines">
            <div class="propLine"
                v-for="watchedProp, index in product.watchedProps"
                :key="index"
                 @click="changeProp([product, index])"
            >
                <i :class="className(index)"></i>
                <div class="check" :class="{checked: watchedProp}">
                    <i v-if="watchedProp" class="fas fa-check"></i>
                    <span v-if="!watchedProp"> &nbsp; </span>
                </div>
                {{ message(index) }}
            </div>
        </div>
        <div class="close">
<!--            <div class="btn" @click="closeEdit"> zavřít nastavení &nbsp; <i class="fas fa-times"></i> </div>-->
<!--            <br>-->
            <div class="btn" @click="removeProduct(product)"> odstranit &nbsp; <i class="fas fa-times"></i> </div>
        </div>
    </div>

</template>
<script>
    import {mapMutations} from "vuex";

    export default {
        props: ['product'],
        methods: {
            ...mapMutations(['changeProp', 'closeEdit', 'removeProduct']),
            className(index) {
                let classes = {
                    inStock: "fas fa-boxes",
                    priceDump: "fas fa-skiing",
                    newDlc: "fas fa-download",
                    newGame: "fas fa-star",
                }
                return classes[index];
            },
            message(index) {
                let messages = {
                    inStock: "Sledovat naskladnění",
                    priceDump: "Sledovat pokles ceny",
                    newDlc: "Sledovat nové DLC",
                    newGame: "Sledovat nový díl v sérii",
                }
                return messages[index];
            }
        }
    }
</script>

<style scoped lang="scss">
    .box {

        .btn {
            background: #222222;
        }

        img {
            max-width: 60%;
            width: 200px;
        }

        h2 {
            flex-grow: 3;
            padding: 10px 20px;
            vertical-align: center;
        }

        &.main {
            min-height: 150px;
        }
        .propLines {
            flex-grow: 3;
            .propLine {
                line-height: 2rem;
                margin: .2rem auto;
                max-width: 350px;
                /*margin: auto;*/
                text-align: left;
                cursor: pointer;


                .fas {
                    min-width: 1.5rem;
                }

                .check {
                    margin: 0 .5rem;
                    display: inline-block;
                    border-radius: 5px;
                    background: #000;
                    border: 1px solid #444444;
                    width: calc(1.5rem + 2px);
                    /*height: calc(1.5rem + 2px);*/
                    line-height: 1.5rem;
                    text-align: center;

                    &.checked {
                        background: greenyellow;
                        color: #000000;
                    }
                }
            }
        }
        .close {
            padding: 10px;
        }

        @media (min-width: 900px) {
            display: flex;
            align-items: center;
            h2 {
                text-align: left;
            }
            /*justify-content: stretch;*/
        }

    }


</style>