<template>
    <div class="card" @click.self="toggleEdit(product)" :class="{ edited:product.edited} ">
        <img @click="toggleEdit(product)" :src="product.img" alt="">

        <div class="product-name">
            <h2 @click="toggleEdit(product)">
                <div class="type">
                    <span v-if="(product['@type'] === 'series')" class="tag">serie</span>
                    <span v-if="(product['@type'] === 'dlc')" class="tag">DLC</span>
                    <span v-if="(product['@type'] === 'game')" class="tag">hra</span>
                </div>
                {{ product.name }}
            </h2>
        </div>

        <card-watched :product="product"></card-watched>
        <div class="btn" @click="toggleEdit(product)">
            {{ (product.edited)? "Zavřít" : "Upravit"  }}
        </div>

        <div class="arrow" v-if="product.edited"></div>
    </div>
    <div class="modal" v-if="product.edited">
        <modal-main :product="product"></modal-main>

<!--        <h3 v-if="product.games">Hry</h3>-->
        <modal-resource v-for="game, index in product.games"
                        :key="index"
                        :product="game"
                        :type="'games'"
        ></modal-resource>

<!--        <h3 v-if="product.series">Serie</h3>-->
        <modal-resource v-for="series, index in product.series"
                        :key="index"
                        :product="series"
                        :type="'series'"
        ></modal-resource>

<!--        <h3 v-if="product.dlcs">Dlc</h3>-->
        <modal-resource v-for="dlc, index in product.dlcs"
                        :key="index"
                        :product="dlc"
                        :type="'dlcs'"
        ></modal-resource>


    </div>
</template>

<script>
    import {mapMutations} from "vuex";
    import CardWatched from "./CardWatched";
    import ModalMain from "./ModalMain";
    import ModalResource from "./ModalResource";

    export default {
        name: 'Card',
        components: {
            CardWatched,
            ModalMain,
            ModalResource
        },
        props: [
            'product',
        ],
        watch: {
            'product.edited'() {
                if (this.product.edited) this.mapProducts(this.product)
            }
        },
        methods: {
            ...mapMutations([
                'edit', "closeEdit", "mapProducts", 'toggleEdit'
            ])
        },
        created() {

        }
    }

</script>

<style lang="scss" scoped>

    .card {
        display: flex;
        /*min-height: 500px;*/
        background: #133552;
        border-radius: 5px;
        position: relative;
        text-align: center;
        padding: 0 0 10px;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        &.edited {
            background: #0f5187;
        }
        .type {
            color: greenyellow;
        }
        img {
            max-width: 100%;
        }

        .product-name {
            display: flex;
            flex-direction: column;
            flex-grow: 2;
            justify-content: center;

            h2 {
                display: block;
                font-size: 18px;
                padding: 10px;
                font-weight: 100;
            }
        }

        .arrow {
            background: black;
            transform: rotate(45deg);
            position: absolute;
            bottom: -28px;
            left: calc(50% - 15px);
            width: 30px;
            height: 30px;
        }
    }

    .modal {
        grid-column: 1 / -1;
        z-index: 1000;
        margin-bottom: 30px;
        .resources {
            display: block;
            margin: 0 -5px;
            .resource {
                flex-grow: 1;
                margin: 0 5px;
                flex-basis: 0;
            }
            @media (min-width: 900px) {
                display: flex;
            }
        }
        h3 {
            margin: 0;
            padding: 5px;
        }
    }


</style>