<template>
    <div class="grid">
        <card v-for="product, index in products" :key="index" :product="product"></card>
    </div>
</template>

<script>

    import {mapMutations, mapState} from "vuex";
    import Card from "./components/Card";

    export default {
        components: {
            Card
        },
        name: 'App',
        methods: {
            ...mapMutations(['loadData'])
        },
        computed: {
            ...mapState(['products'])
        },
        created() {
            this.loadData()
        }
    }
</script>

<style lang="scss" scoped>

    .grid {
        display: grid;
        gap: 10px;
        max-width: 1400px;
        margin: auto;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-flow: row dense;

        @media (min-width: 550px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (min-width: 690px) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media (min-width: 920px) {
            grid-template-columns: repeat(4, 1fr);
        }
        @media (min-width: 1150px) {
            grid-template-columns: repeat(5, 1fr);
        }
        @media (min-width: 1400px) {
            grid-template-columns: repeat(6, 1fr);
        }

    }



</style>
